// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-page-template-js": () => import("/opt/build/repo/src/templates/PageTemplate/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-page-template-js" */),
  "component---src-templates-archive-template-archive-template-js": () => import("/opt/build/repo/src/templates/ArchiveTemplate/ArchiveTemplate.js" /* webpackChunkName: "component---src-templates-archive-template-archive-template-js" */),
  "component---src-templates-post-template-post-template-js": () => import("/opt/build/repo/src/templates/PostTemplate/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-post-template-js" */),
  "component---src-templates-word-template-word-template-js": () => import("/opt/build/repo/src/templates/WordTemplate/WordTemplate.js" /* webpackChunkName: "component---src-templates-word-template-word-template-js" */),
  "component---src-templates-person-template-person-template-js": () => import("/opt/build/repo/src/templates/PersonTemplate/PersonTemplate.js" /* webpackChunkName: "component---src-templates-person-template-person-template-js" */),
  "component---src-templates-taxonomy-template-taxonomy-template-js": () => import("/opt/build/repo/src/templates/TaxonomyTemplate/TaxonomyTemplate.js" /* webpackChunkName: "component---src-templates-taxonomy-template-taxonomy-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}


export const coreActionTypes = {
  GET_SITE_META: 'GET_SITE_META',
  SET_SITE_META: 'SET_SITE_META',
  GET_SITE_META_FAILED: 'GET_SITE_META_FAILED',
  GET_SITE_OPTIONS: 'GET_SITE_OPTIONS',
  SET_SITE_OPTIONS: 'SET_SITE_OPTIONS',
  SET_MENU_STATE: 'SET_MENU_STATE',
  GET_SITE_OPTIONS_FAILED: 'GET_SITE_OPTIONS_FAILED',
};

export const pageActionTypes = {
  GET_PAGE_DATA: 'GET_PAGE_DATA',
  GET_PAGE_FAILED: 'GET_PAGE_FAILED',
  SET_PAGE_DATA: 'SET_PAGE_DATA',
  CLEAR_PAGE_DATA: 'CLEAR_PAGE_DATA',
};

export const postActionTypes = {
  GET_POST_DATA: 'GET_POST_DATA',
  SET_POST_DATA: 'SET_POST_DATA',
  GET_POST_FAILED: 'GET_POST_FAILED',
  CLEAR_POST_DATA: 'CLEAR_POST_DATA',
};

export const teamActionTypes = {
  GET_TEAM_DATA: 'GET_TEAM_DATA',
  SET_TEAM_DATA: 'SET_TEAM_DATA',
  GET_TEAM_FAILED: 'GET_TEAM_FAILED',
  CLEAR_TEAM_DATA: 'CLEAR_TEAM_DATA',

  GET_PERSON_DATA: 'GET_PERSON_DATA',
  SET_PERSON_DATA: 'SET_PERSON_DATA',
  GET_PERSON_FAILED: 'GET_PERSON_FAILED',
  CLEAR_PERSON_DATA: 'CLEAR_PERSON_DATA',
};

export const wordActionTypes = {
  GET_WORD_DATA: 'GET_WORD_DATA',
  SET_WORD_DATA: 'SET_WORD_DATA',
  GET_WORD_FAILED: 'GET_WORD_FAILED',
  CLEAR_WORD_DATA: 'CLEAR_WORD_DATA',
};
